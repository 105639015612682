import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';

const isActiveUntil = (date) => !isPast(addDays(new Date(date), 1));

const specialEvents = [
  {
    href: 'https://contenido.roll-bits.com/linkin-park-31-enero-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi-280/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/linkin-park-circle-310125.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/linkin-park-banner-310125.jpg',
    alt: 'Linkin Park 31 de Enero',
    descriptionText: `Linkin Park: 31 de Enero`,
    isSlider: true,
    isActive: isActiveUntil('2025-01-31'),
  },
  {
    href: 'https://contenido.roll-bits.com/linkin-park-03-feb-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi-280-5se/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/linkin-park-circle-030225.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/linkin-park-banner-030225.jpg',
    alt: 'Linkin Park 03 de Febrero',
    descriptionText: `Linkin Park: 03 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-03'),
  },
  {
    href: 'https://contenido.roll-bits.com/aespa-04-febrero-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/aespa-circle-040225.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/aespa-banner-040225.jpg',
    alt: 'Aespa 04 de Febrero',
    descriptionText: `Aespa: 04 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-04'),
  },
  {
    href: 'https://contenido.roll-bits.com/the-warning-6-feb-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era-d9p/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/warning-circle-060225.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/warning-banner-060225.jpg',
    alt: 'The warning 06 de Febrero',
    descriptionText: `The warning: 06 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-06'),
  },
  {
    href: 'https://contenido.roll-bits.com/justin-timberlake-08-feb-2025-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-ddy-bkc/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/justin-timberlake-circle-070225.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/justin-timberlake-banner-070225.jpg',
    alt: 'Justin Timberlake 08 de Febrero',
    descriptionText: `Justin Timberlake: 08 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-08'),
  },
  {
    href: 'https://contenido.roll-bits.com/the-warning-11-feb-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era-d9p-mxy-7r4/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/warning-circle-110225.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/warning-banner-110225.jpg',
    alt: 'The warning 11 de Febrero',
    descriptionText: `The warning: 11 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-11'),
  },
  {
    href: 'https://contenido.roll-bits.com/the-warning-13-feb-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era-d9p-mxy-7r4-olz/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/warning-circle-130225.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/warning-banner-130225.jpg',
    alt: 'The warning 13 de Febrero',
    descriptionText: `The warning: 13 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-13'),
  },
  {
    href: 'https://contenido.roll-bits.com/twenty-one-pilots-20-feb_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat/?nocache=79274',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-banner.jpg',
    alt: 'Twenty One Pilots 20 de Febrero',
    descriptionText: `Twenty One Pilots: 20 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-20'),
  },
  {
    href: 'https://contenido.roll-bits.com/twenty-one-pilots-22-feb_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi/?nocache=17310',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-banner-2.jpg',
    alt: 'Twenty One Pilots 22 de Febrero',
    descriptionText: `Twenty One Pilots: 22 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-22'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-16-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-d5g/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-1603.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-1603.jpg',
    alt: 'Shakira 16 de Marzo',
    descriptionText: `Shakira: 16 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-16'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-17-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-d5g-2xq/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-170325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-170325.jpg',
    alt: 'Shakira 17 de Marzo',
    descriptionText: `Shakira: 17 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-17'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-19-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-1903.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-1903.jpg',
    alt: 'Shakira 19 de Marzo',
    descriptionText: `Shakira: 19 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-19'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-21-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-2103.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-2103.jpg',
    alt: 'Shakira 21 de Marzo',
    descriptionText: `Shakira: 21 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-21'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-23-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-230325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-230325.jpg',
    alt: 'Shakira 23 de Marzo',
    descriptionText: `Shakira: 23 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-23'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-25-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-250325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-250325.jpg',
    alt: 'Shakira 25 de Marzo',
    descriptionText: `Shakira: 25 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-25'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-27-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-270325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-270325.jpg',
    alt: 'Shakira 27 de Marzo',
    descriptionText: `Shakira: 27 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-27'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-28-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-280325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-280325.jpg',
    alt: 'Shakira 28 de Marzo',
    descriptionText: `Shakira: 28 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-28'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-30-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/shakira-circle-30032025.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/shakira-banner-30032025.jpg',
    alt: 'Shakira 30 de Marzo',
    descriptionText: `Shakira: 30 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-30'),
  },
  {
    href: 'https://contenido.roll-bits.com/olivia-rodrigo-02-abril-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9-num-04u-via-hrw-jrc-fzu-r3i/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/olivia-rodrigo-circle-020425.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/olivia-rodrigo-banner-020425.jpg',
    alt: 'Olivia Rodrigo 02 de Abril',
    descriptionText: `Olivia Rodrigo: 02 de Abril`,
    isSlider: true,
    isActive: isActiveUntil('2025-04-02'),
  },
  {
    href: 'https://contenido.roll-bits.com/olivia-rodrigo-03-abril-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9-num-04u-via-hrw-jrc-fzu-r3i-7s9/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/olivia-rodrigo-circle-030425.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/olivia-rodrigo-banner-030425.jpg',
    alt: 'Olivia Rodrigo 03 de Abril',
    descriptionText: `Olivia Rodrigo: 03 de Abril`,
    isSlider: true,
    isActive: isActiveUntil('2025-04-03'),
  },
  {
    href: 'https://contenido.roll-bits.com/stray-kids-12-abril-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9-num-04u-via-hrw-jrc/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/stray-kids-circle-120425.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/stray-kids-banner-120425.jpg',
    alt: 'Stray Kids 12 de abril',
    descriptionText: `Stray Kids: 12 de Abril`,
    isSlider: true,
    isActive: isActiveUntil('2025-04-12'),
  },
  {
    href: 'https://contenido.roll-bits.com/stray-kids-13-abril-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9-num-04u-via-hrw-jrc-fzu/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/stray-kids-circle-130425.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/stray-kids-banner-130425.jpg',
    alt: 'Stray Kids 13 de abril',
    descriptionText: `Stray Kids: 13 de Abril`,
    isSlider: true,
    isActive: isActiveUntil('2025-04-13'),
  },
  {
    href: 'https://contenido.roll-bits.com/ghost-24-sep-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era-rk6-iag/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/ghost-circle-240925.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/ghost-banner-240925.jpg',
    alt: 'Ghost 24 de Septiembre',
    descriptionText: `Ghost: 24 de Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2025-09-24'),
  },
];

export default specialEvents;
